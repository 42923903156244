import React from 'react'
import { Link } from 'gatsby'

export default function PageHeader({ title, items, breadcrumps, children }) {
  const breadcrump = () => (
    <div className="mb-4">
      <nav className="sm:hidden">
        <a
          href="/"
          className="flex items-center text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:outline-none focus:underline"
        >
          <svg
            className="flex-shrink-0 w-5 h-5 mr-1 -ml-1 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          Back
        </a>
      </nav>
      <nav className="items-center hidden text-sm font-medium leading-5 sm:flex">
        {breadcrumps.map((b, i) => {
          return (
            <>
              <Link
                to={b.link}
                className="text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:outline-none focus:underline"
              >
                {b.text}
              </Link>
              {i < breadcrumps.length - 1 && (
                <svg
                  className="flex-shrink-0 w-5 h-5 mx-2 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </>
          )
        })}
      </nav>
    </div>
  )

  const item = ({ text, icon }) => (
    <div className="flex items-center mt-2 text-sm leading-5 text-gray-500 sm:mr-6">
      <svg
        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        {icon}
      </svg>
      {text}
    </div>
  )
  return (
    <div>
      <section className="pb-4 my-12 border-b border-gray-300">
        {breadcrumps && breadcrump()}
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
              {title}
            </h2>
            <div className="flex flex-col mt-1 sm:mt-0 sm:flex-row sm:flex-wrap">
              {items.map((i) => item(i))}
            </div>
          </div>
          <div className="flex mt-5 lg:mt-0 lg:ml-4 space-x-4">{children}</div>
        </div>
      </section>
    </div>
  )
}
