import React from 'react'
import { Link } from 'gatsby'

// Based on https://tailwindui.com/components/application-ui/elements/buttons
const base = 'inline-flex items-center  font-medium rounded-md'
const styles = {
  primary:
    'border border-transparent text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150',
  secondary:
    'border border-transparent text-teal-700 bg-teal-100 hover:bg-teal-50 focus:outline-none focus:border-teal-300 focus:shadow-outline-teal active:bg-teal-200 transition ease-in-out duration-150',
  white:
    'font-medium text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50',
}

const sizes = {
  sm: 'px-4 py-2 text-sm leading-5',
  md: 'px-4 py-2 text-base leading-6',
  lg: 'px-6 py-3 text-base leading-6',
}

export function Button({ icon, children, style, size, onClick }) {
  return (
    <span className="inline-flex rounded-md shadow-sm">
      <button
        onClick={onClick}
        type="button"
        className={`${base} ${styles[style || 'white']} ${sizes[size || 'md']}`}
      >
        {icon && (
          <svg
            className="w-5 h-5 mr-2 -ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            {icon}
          </svg>
        )}
        {children}
      </button>
    </span>
  )
}

export function ButtonLink({ icon, children, style, size, to }) {
  return (
    <span className="inline-flex rounded-md shadow-sm">
      <Link
        to={to}
        className={`${base} ${styles[style || 'white']} ${sizes[size || 'md']}`}
      >
        {icon && (
          <svg
            className="w-5 h-5 mr-2 -ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            {icon}
          </svg>
        )}
        {children}
      </Link>
    </span>
  )
}
